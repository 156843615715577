import navListItem from './navListItem.vue';
export default {
  props: ['navlist'],
  components: {
    navListItem
  }
};

// import { defineProps } from 'vue'

// const props = defineProps({
//   navlist: {
//     type: Object,
//     require: true
//   },
//   compoents: {
//     navListItem
//   }
// })
// console.log(props)
// const iconName = computed(() => {
//   return `#icon-${props.icon}`
// })