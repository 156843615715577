import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_nav_list_item = _resolveComponent("nav-list-item");
  return _openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.navlist, (item, index) => {
    return _openBlock(), _createElementBlock("div", {
      key: index
    }, [_createVNode(_component_nav_list_item, {
      itemContent: item
    }, null, 8, ["itemContent"])]);
  }), 128);
}