import "core-js/modules/es.array.push.js";
import { unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-85b87d6e"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["src"];
const _hoisted_2 = {
  class: "item-right"
};
const _hoisted_3 = {
  class: "title"
};
const _hoisted_4 = {
  class: "sales"
};
const _hoisted_5 = {
  class: "price"
};
const _hoisted_6 = {
  class: "label"
};
import { useRouter } from 'vue-router';
import { computed } from 'vue';
export default {
  __name: 'navListItem',
  props: {
    itemContent: {
      type: Object,
      require: true
    }
  },
  setup(__props) {
    const props = __props;
    const router = useRouter();
    const toStore = () => {
      router.push('/store');
    };
    const srcValue = computed(() => {
      return props.itemContent.pic;
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", {
        class: "item",
        onClick: toStore
      }, [_createElementVNode("img", {
        src: _unref(srcValue)
      }, null, 8, _hoisted_1), _createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, _toDisplayString(__props.itemContent.title), 1), _createElementVNode("div", _hoisted_4, "销量：" + _toDisplayString(__props.itemContent.sales), 1), _createElementVNode("div", _hoisted_5, "￥" + _toDisplayString(__props.itemContent.price), 1), _createElementVNode("div", _hoisted_6, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.itemContent.label, i => {
        return _openBlock(), _createElementBlock("div", {
          key: i
        }, _toDisplayString(i), 1);
      }), 128))])])]);
    };
  }
};